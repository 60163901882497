<template>
  <el-row id="forecast-component" :gutter="20" class="mb-5">
    <el-col
      :xs="24"
      class="mb-5"
      :lg="8"
      v-for="(revenueData, index) in revenueCardData"
      :key="index"
    >
      <RevenueCard
        :roi="revenueData.roi"
        :color="configureCardColor(revenueData.scenario)"
        :current-revenue="revenueData.currentRevenue"
        :predicted-revenue="revenueData.predictedRevenue"
        :compound-annual-growth-rate="revenueData.compoundAnnualGrowthRate"
        :exit-multiple="revenueData.exitMultiple"
        :exit-valuation="revenueData.exitValuation"
      />
    </el-col>
  </el-row>

  <CardToolbar description="Revenue forecast Master Rights" :title="title">
    <template v-slot:toolbar>
      <a
        class="
          btn btn-sm btn-color-muted btn-active
          active
          btn-active-primary
          px-4
        "
        @click="resetData"
        data-bs-toggle="modal"
        data-bs-target="#artist_forecast_modal"
        >Configure</a
      >
    </template>
    <template v-slot:body>
      <RevenueChart
        v-if="forecastValuesConfigured"
        :series="graphSeriesData"
        :colors="['#50CD89', '#009EF7', '#F1416C']"
        :currency-values="true"
        :categories="graphCategoriesData"
        :stroke-width="[5, 5, 5]"
      />
    </template>
  </CardToolbar>
  <ConfigureForecastModal
    @forecast-config="calculateForecastValues"
    :forecast-values-configured="forecastValuesConfigured"
    :forecast-configuration-values="forecastConfigurationValues"
    id="artist_forecast_modal"
    :scenarios="scenarios"
  />
</template>

<script>
/* eslint-disable */
import { computed, inject, onMounted, ref, watch } from "vue";
import ConfigureForecastModal from "./ConfigureForecastModal";
import CardToolbar from "./CardToolbar";
import RevenueCard from "./RevenueCard";
import RevenueChart from "./RevenueChart";

export default {
  name: "Forecast",
  components: {
    CardToolbar,
    RevenueCard,
    RevenueChart,
    ConfigureForecastModal,
  },
  props: {
    currentSpotifyFollowers: {
      type: Number,
      required: true,
    },
    revenueLast12Months: {
      type: Number,
      required: true,
    },
    spotifyFollowersAYearAgo: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      default: "Forecast",
    },
  },
  setup(props) {
    const growthRate = inject("growthRate");
    const forecastValuesConfigured = ref(false);
    const graphSeriesData = ref([]);
    const graphCategoriesData = ref([]);
    const revenueCardData = ref([]);
    const configureForecastModalButton = ref(null);
    const forecastConfigurationValues = ref({});
    const currentYear = ref(new Date().getFullYear());
    // forecastConfigurationValues.value.averagePlays = {
    //   title: "Average number of play per monthly lister",
    //   type: "averagePlays",
    //   positive: 8,
    //   medium: 5,
    //   negative: 2,
    // };
    // forecastConfigurationValues.value.spotifyShare = {
    //   title: "Market Share spotify vs. other Streamers",
    //   type: "spotifyShare",
    //   positive: 34,
    //   medium: 34,
    //   negative: 34,
    // };
    forecastConfigurationValues.value.exitMultiple = {
      title: "Exit Multiple",
      type: "exitMultiple",
      positive: 14,
      medium: 14,
      negative: 14,
      min: 0,
      max: 30,
      step: 1,
    };
    forecastConfigurationValues.value.streamingShare = {
      title: "Growth Rates",
      type: "streamingShare",
      positive: 4,
      medium: 8,
      negative: 4,
      min: -9999,
      max: 50,
      step: 1,
    };
    const scenarios = ref(["positive", "medium", "negative"]);

    const configureCardColor = (scenario) => {
      if (scenario.includes("positive")) {
        return "success";
      } else if (scenario.includes("medium")) {
        return "primary";
      } else {
        return "danger";
      }
    };

    const entryData = computed(() => {
      return {
        revenueLast12Months: props.revenueLast12Months,
        growthRate: growthRate.value,
      };
    });

    // onMounted(() => {
    //   calculateForecastValues({
    //     scenarios: scenarios.value,
    //     forecastConfig: forecastConfigurationValues.value
    //   })
    // })

    // watch(
    //   () => props.revenueLast12Months,
    //   (value) => {
    //     if (value) {
    //       calculateForecastValues({
    //         scenarios: scenarios.value,
    //         forecastConfig: forecastConfigurationValues.value,
    //       });
    //     }
    //   }
    // );

    watch(
      () => entryData.value,
      (value) => {
        if (value.revenueLast12Months) {
          calculateForecastValues({
            scenarios: scenarios.value,
            forecastConfig: forecastConfigurationValues.value,
          });
        }
        if (value.growthRate.followersIncreased) {
          forecastConfigurationValues.value.streamingShare.positive =
            value.growthRate.growthRate / 2;
        } else {
          forecastConfigurationValues.value.streamingShare.positive =
            (value.growthRate.growthRate / 2) * -1;
          forecastConfigurationValues.value.streamingShare.min =
            (value.growthRate.growthRate / 2) * -1;
        }
      },
      { immediate: true, deep: true }
    );

    const resetData = () => {
      forecastValuesConfigured.value = false;
      graphSeriesData.value = [];
      revenueCardData.value = [];
    };

    const calculateForecastValues = ({ scenarios, forecastConfig }) => {
      resetData();
      scenarios.forEach((config) => {
        const configValues = {
          // averagePlays: forecastConfig["averagePlays"][config],
          // spotifyShare: forecastConfig["spotifyShare"][config],
          streamingShare: forecastConfig["streamingShare"][config],
          exitMultiple: forecastConfig["exitMultiple"][config],
        };
        const currentRevenue = props.revenueLast12Months;
        // const currentRevenue = calculateLast12MonthsRevenue(configValues, config); // Removed, providing value from parent
        const growthRateLocal = calculateGrowthRate(
          forecastConfig["streamingShare"][config]
        );
        const predictedRevenue = calculateNext10YearsRevenue(
          currentRevenue,
          growthRateLocal,
          config
        );
        // const compoundAnnualGrowthRate = calculateCAGR(
        //   currentRevenue,
        //   predictedRevenue,
        //   10
        // );
        console.log(growthRateLocal);
        const compoundAnnualGrowthRate = growthRateLocal;
        const exitValuation = calculateExitValuation(
          predictedRevenue,
          configValues.exitMultiple
        );
        const roi = calculateROI(
          currentRevenue,
          configValues.exitMultiple,
          exitValuation
        );
        revenueCardData.value.push({
          scenario: config,
          currentRevenue,
          predictedRevenue,
          compoundAnnualGrowthRate,
          exitValuation,
          exitMultiple: configValues.exitMultiple,
          roi,
        });
      });
      forecastValuesConfigured.value = true;
    };

    const calculateROI = (lastYearRevenue, exitMultiple, exitValuation) => {
      // const firstCalculation = lastYearRevenue * 3;
      // const secondCalculation = exitValuation * exitMultiple;
      // if (firstCalculation === 0 || secondCalculation === 0) {
      //   return 0;
      // }
      // return Number(((secondCalculation / firstCalculation) * 100).toFixed(0));
      return ((exitValuation / (lastYearRevenue * 5)) * 100).toFixed(0);
    };

    const calculateExitValuation = (predictedRevenue, exitMultiple) => {
      return predictedRevenue * exitMultiple;
    };

    const calculateCAGR = (currentRevenue, predictedRevenue, noOfYears) => {
      if (predictedRevenue === 0 || currentRevenue === 0) {
        return 0;
      }
      return Math.pow(predictedRevenue / currentRevenue, 1 / noOfYears) - 1;
    };

    const calculateNext10YearsRevenue = (revenue, growthRate, scenario) => {
      // const data = [];
      // let predictedRevenue = 0;
      // for (let i = 0; i < 10; i++) {
      //   predictedRevenue += Math.trunc(
      //     revenue * (growthRate / 100 + 100 / 100)
      //   );
      //   data.push(predictedRevenue);
      //   // graphCategoriesData.value.push(`Q${i+1}`)
      //   graphCategoriesData.value.push(i + currentYear.value);
      // }
      // graphSeriesData.value.push({
      //   name: changeScenarioName(scenario),
      //   type: "line",
      //   data: data,
      // });
      // return predictedRevenue;
      const data = [];
      let revenueLastYear = revenue;
      let predictedRevenue = revenue;
      for (let i = 0; i < 10; i++) {
        predictedRevenue += Math.trunc(revenue * (growthRate / 100));
        data.push(predictedRevenue);
        // graphCategoriesData.value.push(`Q${i+1}`)
        graphCategoriesData.value.push(i + currentYear.value);
      }
      graphSeriesData.value.push({
        name: changeScenarioName(scenario),
        type: "line",
        data: data,
      });
      return predictedRevenue;
    };

    const changeScenarioName = (scenario) => {
      if (scenario === "positive") {
        return "50% of Artist Growth";
      } else if (scenario === "medium") {
        return "Market Growth";
      } else if (scenario === "negative") {
        return "50% of Market Growth";
      }
    };
    const calculateGrowthRate = (config) => {
      return config;
      //  console.log(config)
      // return props.spotifyFollowersAYearAgo / props.currentSpotifyFollowers;
    };

    const calculateLast12MonthsRevenue = (forecastValue) => {
      const totalSpotifyPlays =
        props.currentSpotifyFollowers * forecastValue.averagePlays;
      const totalStreamsPerMonth =
        (totalSpotifyPlays / (forecastValue.spotifyShare / 100)) *
        ((100 - forecastValue.spotifyShare) / 100);
      const totalMonthlyStreamingPlatformsRevenues =
        totalStreamsPerMonth * 0.0024;
      const totalYearlyStreamingPlatformsRevenues =
        totalMonthlyStreamingPlatformsRevenues * 12;
      return Math.trunc(
        (totalYearlyStreamingPlatformsRevenues /
          (forecastValue.streamingShare / 100)) *
          ((100 - forecastValue.streamingShare) / 100)
      );
    };
    return {
      calculateForecastValues,
      configureCardColor,
      configureForecastModalButton,
      forecastValuesConfigured,
      forecastConfigurationValues,
      graphSeriesData,
      graphCategoriesData,
      revenueCardData,
      resetData,
      scenarios,
      currentYear,
      changeScenarioName,
    };
  },
};
/* eslint-disable */
</script>

<style scoped></style>
